import React from 'react'
import { IFooterProps } from './types'
import { functions, useApp, useWidth } from '@wap-client/core'
import { Row, Column, Container } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor'
import Icon from '@/components/base/icon'
import Image from 'components/base/image'
import SocialInfos from '@/components/widgets/social-infos/SocialInfos'
import LanguageSwitch from '@/components/widgets/language-switch'

const Footer: React.FC<IFooterProps> = ({
  data,
  menu,
  copyright,
  logo,
  policyMenu,
}) => {
  const app = useApp()
  const width = useWidth()

  return (
    <footer>
      <Container size="extended">
        <Row className="footer-up">
          <Column xs={{ size: 12 }} xl={{ size: 8 }}>
            <ul className="footer-up-nav">
              {menu?.map((navItem, index) => (
                <li className="footer-up-nav-item" key={index}>
                  <Anchor {...navItem}>{navItem.title}</Anchor>
                </li>
              ))}
            </ul>
          </Column>
          <Column
            xs={{ size: 12 }}
            xl={{ size: 4 }}
            style={{
              display: 'flex',
              justifyContent: width < 1025 ? 'flex-start' : 'flex-end',
            }}
          >
            <SocialInfos themeSettings={true} />
          </Column>
        </Row>

        <Row className="footer-adress">
          {data?.map((adress, index) => (
            <Column xs={{ size: 12 }} md={{ size: 4 }} key={index}>
              <h3 className="footer-adress-title">{adress.title}</h3>
              <span className="footer-adress-adress">{adress.address}</span>
              <a
                className="footer-adress-phone"
                href={`tel:${adress.phone}`}
                target="_blank"
              >
                {adress.phone}
              </a>
              <div className="footer-adress-get-directions">
                <Anchor {...adress.getDirections} />
                <Icon name="directions" size="large" />
              </div>
            </Column>
          ))}
        </Row>

        <Row className="footer-bottom">
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 6 }}
            className="footer-bottom-infos"
          >
            {logo && (
              <>
                <figure className="footer-bottom-infos-logo">
                  <a target="_blank" href="https://clkgroup.com">
                    <Image {...logo} alt={logo.alt} />
                  </a>
                </figure>
                <div>
                  <span className="footer-bottom-infos-logo-asg">
                    {app.settings.translations['asg-group']}
                  </span>
                  <span>@ BuKafa</span>
                  {copyright && <span className="copyright">{copyright}</span>}
                </div>
              </>
            )}
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 6 }}
            className={functions.classnames(
              'footer-bottom-policy',
              app.page.path.includes(app.settings.homepage) && 'homepage'
            )}
          >
            <h5>
              {logo && (
                <figure className="mobile-logo">
                  <Image {...logo} alt={logo.alt} />
                </figure>
              )}
              <LanguageSwitch />
            </h5>
            <ul className="footer-bottom-policy-policies">
              {policyMenu?.map((link, index) => (
                <li className="footer-bottom-policy-policies-link" key={index}>
                  <Anchor {...link}>{link?.title}</Anchor>
                </li>
              ))}
            </ul>
          </Column>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
