import React from 'react'
import { ILanguageSwitchProps } from './types'
import useHandleChanceLanguage from '@/hook/useHandleChanceLanguage'
import { functions, useApp } from '@wap-client/core'

const LanguageSwitch: React.FC<ILanguageSwitchProps> = () => {
  const app = useApp()
  const { chanceLanguage } = useHandleChanceLanguage()

  return (
    <div className="language-switch">
      {app.languages.map((language) => (
        <button
          type="button"
          key={language.id}
          onClick={() => chanceLanguage(language.id)}
          className={functions.classnames(
            app.language === language.name && 'current-lang'
          )}
        >
          {language?.name?.substring(0, 2).toLocaleUpperCase('en')}
        </button>
      ))}
    </div>
  )
}

export default LanguageSwitch
