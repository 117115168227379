import { path, useApp } from '@wap-client/core'
import { pageService } from '@wap-client/services'
import { useRouter } from 'next/router'
import { UseHandleChanceLanguageResult } from './types'

function useHandleChanceLanguage(): UseHandleChanceLanguageResult {
  const router = useRouter()
  const app = useApp()

  const chanceLanguage = (language?: string) => {
    if (language) {
      pageService
        .findPath(app.environment, { path: app.page.path, language })
        .then((slug) => {
          const params = new URLSearchParams(window.location.search)

          router.push(
            `${path.app(app.environment, slug)}${
              params.toString() && '?' + params.toString()
            }`
          )
        })
        .catch(() => {
          const homepagePath = app.settings.homepage

          if (homepagePath) {
            pageService
              .findPath(app.environment, { path: homepagePath, language })
              .then((slug) => {
                router.push(path.app(app.environment, slug))
              })
          }
        })
    }
  }

  return { chanceLanguage }
}

export default useHandleChanceLanguage
