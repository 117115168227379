import React from 'react'

import { useApp } from '@wap-client/core'

import Anchor from '@/components/base/anchor/Anchor'
import Icon, { IconName } from '@/components/base/icon'

import { SocialInfosProps } from './types'

const SocialInfos: React.FC<SocialInfosProps> = ({
  socialMedia,
  mail,
  themeSettings,
}) => {
  const app = useApp()
  const socials = Object.entries(app.settings.socials)
  const email = app.settings.translations['mail']

  const searchSocialIcon = (socialName: string) => {
    switch (socialName) {
      case 'youtube':
        return 'youtube'
      case 'instagram':
        return 'insta'
      default:
        return socialName
    }
  }

  const renderSocials = () => {
    return (
      <div className="media">
        {socialMedia && (
          <>
            {socialMedia.instagram && (
              <Anchor href={socialMedia.instagram}>
                <Icon name="insta" />
              </Anchor>
            )}
            {socialMedia.linkedin && (
              <Anchor href={socialMedia.linkedin}>
                <Icon name="linkedin" />
              </Anchor>
            )}
            {socialMedia.youtube && (
              <Anchor href={socialMedia.youtube}>
                <Icon name="x" />
              </Anchor>
            )}
          </>
        )}

        {themeSettings &&
          socials.length > 0 &&
          Object.entries(app.settings.socials).map((social, index) => {
            if (social[1].length > 0) {
              return (
                <Anchor key={index} href={social[1]}>
                  {searchSocialIcon(social[0]) && (
                    <Icon name={searchSocialIcon(social[0]) as IconName} />
                  )}
                </Anchor>
              )
            }
          })}
      </div>
    )
  }

  const renderMail = () => {
    return (
      <>
        {mail && (
          <div className="mail">
            <a className="socials-mail" href={`mailto:${mail}`} target="_blank">
              {mail}
            </a>
          </div>
        )}

        {themeSettings && email.length > 0 && (
          <div className="mail">
            <a
              className="socials-mail"
              href={`mailto:${email}`}
              target="_blank"
            >
              {email}
            </a>
          </div>
        )}
      </>
    )
  }

  if (!socialMedia && !mail && !themeSettings) {
    return null
  }

  return (
    <div className="socials">
      {renderSocials()}
      {renderMail()}
    </div>
  )
}

export default SocialInfos
